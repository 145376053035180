import React from "react"
import styled from "styled-components"

import Twitter from "../../../assets/images/svg/twitter.svg"
import Facebook from "../../../assets/images/svg/facebook.svg"
import LinkedIn from "../../../assets/images/svg/linkedin.svg"

import { UnstyledList } from "../../global/common/essentials"

import SiteLanguage from "../../../helpers/siteLanguage"

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: var(--box-padding-vertical, 2.7rem)
    var(--box-padding-horizontal, 2.7rem);
  background-color: var(--dark-gray);
`

const Title = styled.p`
  color: #fff;
`

const List = styled(UnstyledList)`
  display: flex;
  margin-right: -0.7rem;
`

const Link = styled.a`
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.7;
  }
`

const Icon = styled.svg`
  width: 1rem;
  height: 1rem;

  margin: 0 0.7rem;

  fill: #fff;
`

const Share = ({ title }) => {
  const currentUrl =
    typeof window !== "undefined"
      ? window.location.href
      : process.env.GATSBY_FRONT_END_URL

  return (
    <Box>{console.log(SiteLanguage())}
      <Title>{SiteLanguage() === "en" ? 'Share post:' : 'Udostępnij:'}</Title>
      <List>
        <li>
          <Link
            target="_blank"
            rel="nofollow noopener noreferrer"
            href={`http://twitter.com/share?url=${currentUrl}&text=${title}`}
          >
            <Icon as={Twitter} />
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="nofollow noopener noreferrer"
            href={`https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${currentUrl}`}
          >
            <Icon as={Facebook} />
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="nofollow noopener noreferrer"
            href={`https://www.linkedin.com/shareArticle?mini=true&title=${title}&url=${currentUrl}`}
          >
            <Icon as={LinkedIn} />
          </Link>
        </li>
      </List>
    </Box>
  )
}

export default Share
